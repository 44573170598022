import { useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import striptags from 'striptags'
import { useCourseContext } from 'components/Courses/CourseContext'
import { TbCameraPlus, TbTrash } from 'react-icons/tb'
import { Panel, Button, Stack, Divider } from 'rsuite'
import { AppEditor } from 'components/App/Editor/AppEditor'
import { fetchapi, upload } from 'lib/fetch'
import FeedCard from 'components/Feed/FeedCard'

export default function DiscussionPost({ className }) {
	const { courseid, activeStep, updateCourse } = useCourseContext()

	const [mediaPreviews, setMediaPreviews] = useState([])
	const [postBody, setPostBody] = useState('')
	//const [captionDialog, setCaptionDialog] = useState(false)

	const [hasPost, setHasPost] = useState()

	useEffect(() => {
		; (async () => {
			if (activeStep?.discussid !== null) {
				const res = await fetchapi(`/course/post/${activeStep.discussid}`)

				if (res && res.post) {
					setHasPost(res.post)
				}
			}
		})()
	}, [])

	function addFile(event) {
		var previews = [...mediaPreviews]

		for (let i = 0; i < event.target.files.length; i++) {
			const fileId = crypto.randomUUID()
			const file = event.target.files[i]
			const newFile = {
				id: fileId,
				src: URL.createObjectURL(file),
				type: file.type,
				raw: file,
				caption: '',
			}
			previews.push(newFile)
		}

		setMediaPreviews(previews)
	}

	function removeFile(id) {
		return function () {
			const list = mediaPreviews.slice()
			const i = list.findIndex(function (v) {
				return v.id === id
			})

			if (i > -1) {
				list.splice(i, 1)
				setMediaPreviews(list)
			}
		}
	}

	async function submitPost() {
		if (striptags(postBody) !== '') {
			const data = new FormData()

			data.append('groupid', courseid)
			data.append('title', activeStep?.title)
			data.append('body', postBody)

			for (let i = 0; i < mediaPreviews.length; i++) {
				data.append('attachments', mediaPreviews[i].raw)
				//data.append('captions', mediaPreviews[i].caption);
			}

			const res = await upload(
				`/course/${courseid}/${activeStep?.id}/discussion/submit`,
				data
			)

			if (res.success) {
				setHasPost(res.post)
				updateCourse()
			}
		}
	}

	function setMediaCaption(id, text) { }

	if (!activeStep) {
		return <></>
	} else {
		return (
			<>
				{hasPost ? (
					<FeedCard
						item={hasPost}
						className={className}
						footer={false}
						onDelete={updateCourse}
						noLinks
					/>
				) : (
					<>
						{activeStep?.discussion === 'discussion' && (
							<Panel bordered style={{ maxWidth: '906px', margin: 'auto' }}>
								<Stack direction='column' spacing={20} alignItems='strech'>
									<Stack.Item alignSelf='stretch'>
										<form id='upload-form'>
											<input
												id='upload-input'
												type='file'
												name='fileupload'
												accept='image/*'
												multiple='true'
												onChange={addFile}
											/>
											<label
												className='btn btn-outline-secondary upload-button'
												htmlFor='upload-input'
											>
												<TbCameraPlus /> Choose a File
											</label>
										</form>
										<ReactSortable
											id='upload-previews'
											list={mediaPreviews}
											setList={setMediaPreviews}
										>
											{mediaPreviews.map(function (file, seq) {
												return (
													<div className='media-preview'>
														<div
															key={file.id}
															className={
																'upload-tile box box-1x1 ui-state-default' +
																(file.loading ? ' loading' : '')
															}
														>
															<div className='content'>
																<img src={file.src} alt='' />
																<button
																	className='upload-delete'
																	onClick={removeFile(file.id)}
																>
																	<TbTrash />
																</button>
															</div>
														</div>
														{/*}
														<Button
															className='media-caption'
															onClick={() => setCaptionDialog(true)}
														>
															Add Caption
														</Button>
														*/}
													</div>
												)
											})}
										</ReactSortable>
									</Stack.Item>
									<Stack.Item alignSelf='stretch'>
										<AppEditor height='fluid' onChange={setPostBody} />
									</Stack.Item>
									<Stack.Item alignSelf='flex-end'>
										<Button
											onClick={submitPost}
											style={{
												border: '2px solid #4fd691',
												backgroundColor: '#fff'

											}}
										>
											Submit Response
										</Button>
									</Stack.Item>
								</Stack>
							</Panel>
						)}
					</>
				)}
			</>
		)
	}
}
